<template>
  <div class="line-item-v2">
    <div class="d-flex mb-2 align-center justify-space-between">
      <div v-if="!isJob">
        <v-btn-toggle color="cyan" group active-class="line-item-type-active">
          <v-btn
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header('top')"
          >
            Header
          </v-btn>
          <v-btn
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('top', 'service')"
          >
            Service
          </v-btn>
          <v-btn
            v-if="quoteType == 1"
            active-class="line-item-type-active"
            style="background-color: #00bcd4 !important; color: #ffffff"
            color="cyan darken-4"
            class="custom-bold-button"
            value="door_type"
            v-on:click="open_dialog('top', 'door_type')"
          >
            + Door Type
          </v-btn>
          <v-btn
            v-if="quoteType == 2"
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button"
            value="door"
            v-on:click="open_dialog('top', 'door')"
          >
            Door
          </v-btn>
          <v-btn
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button"
            value="part"
            v-on:click="open_dialog('top', 'part')"
          >
            Part
          </v-btn>

          <v-btn
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button"
            value="tool"
            v-on:click="open_dialog('top', 'tool')"
          >
            Tool
          </v-btn>

          <v-btn
            active-class="line-item-type-active"
            color="cyan"
            class="custom-bold-button mr-5"
            value="other"
            v-on:click="add_other('top')"
          >
            Other
          </v-btn>

          <v-btn
            v-if="quoteType == 2"
            active-class="line-item-type-active"
            style="background-color: #00bcd4 !important; color: #ffffff"
            color="cyan darken-4"
            class="custom-bold-button"
            value="door_type"
            v-on:click="open_dialog('top', 'door_type', true)"
          >
            + Door Type
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex align-center">
        <template v-if="false">
          <v-checkbox
            class="my-0 py-0"
            v-model="show_image"
            color="cyan white--text"
            label="Show Image"
          />
          <div class="pl-4 ml-4 custom-border-left">
            <v-checkbox
              class="my-0 py-0"
              v-model="show_price"
              color="cyan white--text"
              label="Show Price"
            />
          </div>
          <div
            v-if="show_price"
            class="d-flex align-center ml-4 pl-4 custom-border-left"
          >
            <div class="mr-2">
              <label for="discount-level" class="mb-0 font-weight-600"
                >Discount Level</label
              >
            </div>
            <div>
              <v-radio-group
                v-on:change="calculate_total()"
                id="discount-level"
                v-model="discount_level"
                row
                hide-details
                class="px-0 py-0"
              >
                <v-radio
                  label="Line Item"
                  value="line_item"
                  color="cyan"
                ></v-radio>
                <v-radio
                  label="Transaction"
                  value="transaction"
                  color="cyan"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="d-flex grey lighten-4">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          style="width: 100%"
        >
          Line Item
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
        >
          Unit
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
        >
          Quantity
        </div>
        <template v-if="!isJob">
          <div
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
          >
            Unit Cost
          </div>
          <div
            v-if="show_price && discount_level == 'line_item'"
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
          >
            Discount
          </div>
          <div
            v-if="show_price"
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
            style="display: flex; justify-content: center; align-items: center"
          >
            Total
            <template v-if="quoteType == 2">
              <v-tooltip left content-class="custom-left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    small
                    color="color-custom-blue"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span
                  >(No. of Contract
                  Days)&nbsp;&nbsp;X&nbsp;&nbsp;(Quantity)&nbsp;&nbsp;X&nbsp;&nbsp;(Unit
                  Cost)</span
                >
              </v-tooltip>
            </template>
          </div>
          <div
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.action}px; max-width: ${vWidth.action}px; width: ${vWidth.action}px`"
          ></div>
        </template>
      </div>

      <Draggable
        tag="ul"
        v-model="selected_line_items"
        class="line-item-drag-group"
        draggable=".line-item-drag"
        handle=".line-item-drag-icon"
        ghost-class="line-item-ghost"
        animation="200"
        group="line-item-drag"
        v-on:start="drag = true"
        v-on:end="drag = false"
        v-on:change="
          change_drag();
          emit_values();
        "
      >
        <transition-group
          tag="li"
          type="transition"
          :name="!drag ? 'flip-list' : null"
          v-for="(row, index) in selected_line_items"
          class="line-item-drag"
          :key="`line-item-drag-${index}`"
        >
          <div
            :key="`line-item-drag-children-${index}`"
            :id="`line-item-drag-${index}`"
            class="d-flex my-2"
            style="position: relative"
            :class="{
              'item-is-child': row.is_child,
              'item-is-second-child': row.is_second_child,
            }"
          >
            <div
              class="font-weight-600 text-center"
              :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
            >
              <template v-if="!isJob">
                <v-icon
                  v-if="!row.is_child && !row.is_second_child"
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </template>
            </div>

            <template v-if="row.is_child || row.is_second_child">
              <div
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                <template v-if="!isJob">
                  <v-icon
                    v-if="row.is_child"
                    class="line-item-drag-icon cursor-move"
                    color="color-custom-blue"
                    >mdi-drag</v-icon
                  >
                </template>
              </div>
              <div
                v-if="row.is_second_child"
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                <template v-if="!isJob">
                  <v-icon
                    class="line-item-drag-icon cursor-move"
                    color="color-custom-blue"
                    >mdi-drag</v-icon
                  >
                </template>
              </div>
            </template>

            <div class="text-left" style="width: 100%">
              <div class="d-flex" v-if="row?.product_type == 'header'">
                <div style="width: 100%" :class="{ 'ml-4': show_image }">
                  <v-text-field
                    v-model="row.product"
                    class="line-item-header-text px-0"
                    :placeholder="lodash.capitalize(row.product_type)"
                    dense
                    :maxlength="250"
                    filled
                    solo
                    flat
                    v-on:click="emit_values()"
                    v-on:keyup="emit_values()"
                    color="cyan"
                  >
                    <template v-slot:append>
                      <v-chip
                        color="cyan white--text"
                        label
                        class="lip-type text-uppercase font-weight-600"
                        :class="{
                          product: row.product_type == 'door',
                          service: row.product_type == 'service',
                          equipment: row.product_type == 'parts',
                          other: row.product_type == 'other',
                          tool: row.product_type == 'tools',
                          door_type: row.product_type == 'door_type',
                          'btx-header': row.product_type == 'header',
                        }"
                      >
                        {{ lodash.lowerCase(row.type) }}
                      </v-chip>
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div class="d-flex" v-else>
                <v-avatar v-if="show_image" size="80">
                  <img
                    v-if="row?.image?.url"
                    :src="row?.image?.url"
                    :alt="row?.image?.name"
                  />
                  <img v-else :src="$defaultImage" />
                </v-avatar>
                <div style="width: 100%" :class="{ 'ml-4': show_image }">
                  <v-text-field
                    v-model="row.product"
                    class="line-item-header-text px-0"
                    :placeholder="lodash.capitalize(row.product_type)"
                    dense
                    :maxlength="250"
                    filled
                    solo
                    flat
                    v-on:click="emit_values()"
                    v-on:keyup="emit_values()"
                    color="cyan"
                  >
                    <template v-slot:append>
                      <v-chip
                        color="cyan white--text"
                        label
                        class="lip-type text-uppercase font-weight-600"
                        :class="{
                          product: row.product_type == 'door',
                          service: row.product_type == 'service',
                          equipment: row.product_type == 'parts',
                          other: row.product_type == 'other',
                          tool: row.product_type == 'tools',
                          door_type: row.product_type == 'door_type',
                          'btx-header': row.product_type == 'header',
                        }"
                      >
                        {{ lodash.lowerCase(row.type) }}
                      </v-chip>
                      <template v-if="row.product_type == 'door_type'">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              color="cyan white--text"
                              label
                              v-on:click="
                                update_qty_dialog(row, index, row.qty_array)
                              "
                              class="door-type-action text-uppercase font-weight-600"
                            >
                              <v-icon small>mdi-cog</v-icon>
                            </v-chip>
                          </template>
                          Manage Door Type.
                        </v-tooltip>
                      </template>
                    </template>
                  </v-text-field>
                  <v-textarea
                    class="px-0"
                    v-model="row.description"
                    placeholder="Description"
                    dense
                    filled
                    solo
                    flat
                    rows="2"
                    auto-grow
                    v-on:keyup="emit_values()"
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <template v-if="row?.product_type != 'header'">
              <div
                v-if="show_price"
                class="text-center position-relative"
                :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
              >
                <v-select
                  :items="lUOM"
                  v-model="row.unit"
                  hide-details
                  item-color="cyan"
                  class="line-item-header-text text-h6 px-0"
                  placeholder="Unit"
                  :readonly="isJob"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="emit_values()"
                  color="cyan"
                />
                <div
                  v-if="
                    (row?.product_type == 'service' ||
                      row?.product_type == 'parts') &&
                    row.selling_cost > 0
                  "
                  style="min-width: 200px; padding: 6px 5px"
                  class="font-weight-700 text-left"
                >
                  Original Price: {{ formatMoney(row.selling_cost) }}
                </div>
                <div
                  v-if="
                    isQuotation &&
                    (row?.product_type == 'service' ||
                      row?.product_type == 'door_type' ||
                      row?.product_type == 'parts')
                  "
                  style="min-width: 110px"
                  class="ml-1"
                >
                  <v-checkbox
                    v-model="row.hide_values"
                    color="cyan"
                    v-on:change="emit_values()"
                    label="Hide Values"
                  />
                </div>
                <div style="position: absolute; left: 10px">
                  <template v-if="row.prev_line_item_id">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="orange darken-3 white--text"
                          class="font-weight-700 text-capitalize"
                          small
                          >M</v-chip
                        >
                      </template>
                      <span>Already have Maintenance</span>
                    </v-tooltip>
                  </template>
                  <template v-if="row.request_type">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="cyan darken-3 white--text"
                          class="ml-2 font-weight-700 text-capitalize"
                          small
                        >
                          {{ row?.request_type?.charAt(0) }}
                        </v-chip>
                      </template>
                      <span class="text-capitalize"
                        >Type : {{ row?.request_type }}</span
                      >
                    </v-tooltip>
                  </template>
                </div>
              </div>
              <div
                v-if="show_price"
                class="text-center"
                :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Quantity"
                  :maxlength="6"
                  dense
                  filled
                  solo
                  flat
                  :readonly="
                    isJob ||
                    (!row.is_custom_door_type &&
                      row.product_type == 'door_type' &&
                      quoteType == 2)
                  "
                  type="number"
                  v-model="row.quantity"
                  :prepend="row.uom"
                  v-on:keyup="calculate_total()"
                  v-on:change="calculate_total()"
                  v-on:keypress="isNumber($event)"
                  color="cyan"
                />
              </div>
              <template v-if="!isJob">
                <div
                  class="text-center"
                  :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
                >
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Unit Cost"
                    :maxlength="6"
                    dense
                    filled
                    solo
                    flat
                    :disabled="row.custom_val ? true : false"
                    type="number"
                    v-model="row.rate"
                    v-on:keyup="calculate_total()"
                    v-on:change="calculate_total()"
                    v-on:keypress="
                      limitDecimal($event, row.rate);
                      row.custom_val = null;
                    "
                    color="cyan"
                  />
                  <div class="d-flex flex-column align-start">
                    <v-text-field
                      :disabled="row.rate ? true : false"
                      class="line-item-header-text px-0 py-0 line-item-custom-value"
                      placeholder="Custom Value"
                      dense
                      :style="`min-width: ${
                        vWidth.rate + vWidth.total
                      }px; max-width: ${vWidth.rate + vWidth.total}px; width: ${
                        vWidth.rate + vWidth.total
                      }px`"
                      filled
                      solo
                      flat
                      v-model="row.custom_val"
                      v-on:keypress="row.rate = null"
                      v-on:change="calculate_total()"
                      color="cyan"
                    />
                  </div>
                </div>
                <div
                  v-if="show_price && discount_level == 'line_item'"
                  class="text-center"
                  :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
                >
                  <div class="d-flex">
                    <v-text-field
                      hide-details
                      class="line-item-header-text px-0"
                      placeholder="Discount"
                      :maxlength="6"
                      dense
                      filled
                      solo
                      flat
                      type="number"
                      v-model="row.discount_value"
                      v-on:keyup="calculate_total()"
                      v-on:keypress="limitDecimal($event, row.discount_value)"
                      color="cyan"
                      style="width: 50%; min-width: unset"
                    />
                    <v-select
                      :items="discount_type_list"
                      v-model="row.discount_type"
                      hide-details
                      item-text="type"
                      item-value="value"
                      item-color="cyan"
                      class="line-item-header-text discount-type text-h6 px-0"
                      placeholder="Type"
                      dense
                      filled
                      solo
                      flat
                      v-on:change="calculate_total()"
                      color="cyan"
                      style="width: 50%"
                    />
                  </div>
                </div>
                <div
                  v-if="show_price"
                  class="text-center"
                  :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
                >
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Total"
                    dense
                    filled
                    solo
                    readonly
                    :maxlength="6"
                    flat
                    type="number"
                    v-model="row.total"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, row.total)"
                    color="cyan"
                  />
                </div>
              </template>
            </template>
            <div
              class="font-weight-600 text-right"
              :style="`min-width: ${vWidth.action}px; max-width: ${vWidth.action}px; width: ${vWidth.action}px`"
            >
              <template
                v-if="
                  row.product_type != 'tools' &&
                  row.product_type != 'other' &&
                  !isJob
                "
              >
                <v-menu v-if="!row.is_second_child" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="blue darken-4">
                      <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="py-0" min-width="150px">
                    <v-list-item
                      link
                      v-if="false && !row.is_child"
                      class="border-bottom"
                      v-on:click="add_child(index, 'header', false)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add Header</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="border-bottom"
                      v-on:click="add_child(index, 'service', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add Service</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="border-bottom"
                      v-on:click="add_child(index, 'part', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add Part</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="border-bottom"
                      v-on:click="add_child(index, 'tool', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add Tool</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="border-bottom"
                      v-on:click="add_child(index, 'other', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add Other</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="border-bottom"
                      v-on:click="add_child(index, 'door', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add new Door</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      v-on:click="add_child(index, 'door_type', row.is_child)"
                    >
                      <v-list-item-title class="font-weight-600">
                        Add new Door Type</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-btn v-on:click="remove_row(index)" icon color="red lighten-1">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </transition-group>
      </Draggable>
      <template v-if="!selected_line_items.length">
        <div class="custom-border-top p-3">
          <p
            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
          >
            <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
            Oops... Nothing Found.
          </p>
        </div>
      </template>
    </div>
    <div v-if="!isJob" class="d-flex mt-2">
      <v-btn-toggle color="cyan" group active-class="line-item-type-active">
        <v-btn
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button"
          value="header"
          v-on:click="add_header('bottom')"
        >
          Header
        </v-btn>
        <v-btn
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button"
          value="service"
          v-on:click="open_dialog('bottom', 'service')"
        >
          Service
        </v-btn>
        <v-btn
          v-if="quoteType == 1"
          active-class="line-item-type-active"
          style="background-color: #00bcd4 !important; color: #ffffff"
          color="cyan darken-4"
          class="custom-bold-button"
          value="door_type"
          v-on:click="open_dialog('bottom', 'door_type')"
        >
          + Door Type
        </v-btn>
        <v-btn
          v-if="quoteType == 2"
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button"
          value="door"
          v-on:click="open_dialog('bottom', 'door')"
        >
          Door
        </v-btn>
        <v-btn
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button"
          value="part"
          v-on:click="open_dialog('bottom', 'part')"
        >
          Part
        </v-btn>

        <v-btn
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button"
          value="tool"
          v-on:click="open_dialog('bottom', 'tool')"
        >
          Tool
        </v-btn>

        <v-btn
          active-class="line-item-type-active"
          color="cyan"
          class="custom-bold-button mr-5"
          value="other"
          v-on:click="add_other('bottom')"
        >
          Other
        </v-btn>
        <v-btn
          v-if="quoteType == 2"
          active-class="line-item-type-active"
          style="background-color: #00bcd4 !important; color: #ffffff"
          color="cyan darken-4"
          class="custom-bold-button"
          value="door_type"
          v-on:click="open_dialog('bottom', 'door_type', true)"
        >
          + Door Type
        </v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="!isJob">
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Sub Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(subtotal) }}
            </td>
          </tr>
          <template v-if="discount_level == 'transaction'">
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                Discount
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    :maxlength="6"
                    filled
                    solo
                    flat
                    v-model="discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, discount_value)"
                    color="cyan"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(discount) }}
              </td>
            </tr>
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                <v-text-field
                  style="width: 250px"
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount Label"
                  dense
                  :maxlength="50"
                  filled
                  solo
                  flat
                  v-model="ctx_discount_label"
                  v-on:keyup="calculate_total()"
                  color="cyan"
                />
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    :maxlength="6"
                    filled
                    solo
                    flat
                    v-model="ctx_discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, ctx_discount_value)"
                    color="cyan"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="ctx_discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(ctx_discount) }}
              </td>
            </tr>
          </template>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ lGST }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                v-on:change="calculate_total()"
                v-model="tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Taxable Amount: ${formatMoney(gst_amount)}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(gst) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Adjustment
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                :maxlength="6"
                solo
                flat
                v-model="adjustment"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, adjustment)"
                color="cyan"
              >
                <template v-slot:append>
                  <v-btn depressed v-on:click="auto_adjust()">Auto</v-btn>
                </template>
              </v-text-field>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(adjustment) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600 font-size-19 black--text"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600 font-size-19 black--text"
            >
              {{ formatMoney(total) }}
            </td>
          </tr>
          <template v-if="isQuotation">
            <tr
              v-for="(row, index) in additional_rows"
              :key="`additional-rows-${index}`"
            >
              <td
                width="85%"
                valign="middle"
                align="right"
                class="font-weight-600 font-size-19 black--text"
                colspan="2"
              >
                <v-text-field
                  style="width: 500px"
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Label"
                  v-model="row.label"
                  dense
                  v-on:change="emit_values()"
                  filled
                  solo
                  flat
                  color="cyan"
                />
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600 font-size-19 black--text"
              >
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0 has-delete-outer-icon"
                  placeholder="Value"
                  v-model="row.value"
                  dense
                  filled
                  solo
                  flat
                  v-on:paste="onPaste"
                  :maxlength="10"
                  v-on:keypress="limitDecimal($event, row.value)"
                  color="cyan"
                  prefix="$"
                  v-on:change="emit_values()"
                  append-outer-icon="mdi-delete"
                  v-on:click:append-outer="remove_add_rows(index)"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td colspan="3" align="right">
                <v-btn
                  class="custom-bold-button"
                  depressed
                  v-on:click="push_add_rows()"
                  color="cyan white--text"
                  >Add Row</v-btn
                >
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <LineItemDialog
      :key="`line-item-dialog-${dialog_key}`"
      :product-type="product_type"
      :product-dialog="product_dialog"
      :force-quote-type="force_quote_type"
      :is-custom-door-type="is_custom_door_type"
      v-on:update-values="update_line_item($event)"
      v-on:close="
        product_dialog = false;
        is_custom_door_type = false;
      "
    />
    <LineItemHeaderDialog
      :key="`line-item-dialog-header-${header_dialog_key}`"
      :header-dialog="header_dialog"
      v-on:update-values="update_header($event)"
      v-on:close="header_dialog = false"
    />
    <UpdateDoorType
      :key="`line-item-door-type-dialog-${door_dialog_key}`"
      :def-object="def_object"
      :qty-qialog="qty_dialog"
      :qty-array="qty_array"
      :qty-object="qty_object"
      :quote-type="force_qt ? 2 : quoteType"
      :show-breakdown.sync="showBreakdown"
      :hide-value.sync="hideValue"
      v-on:updateBreakdown="update_breakdown($event)"
      v-on:updateHideValue="update_hide_door_values($event)"
      v-on:close="close_qty_dialog('quote')"
      v-on:save="save_qty($event, 'quote')"
    />
    <UpdateJobDoorType
      :key="`line-item-job-door-type-dialog-${door_dialog_key}`"
      :def-object="def_object"
      :qty-qialog="qty_job_dialog"
      :qty-array="qty_array"
      :qty-object="qty_object"
      v-on:close="close_qty_dialog('job')"
      v-on:save="save_qty($event, 'job')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
import LineItemDialog from "@/view/components/LineItemDialog.vue";
import LineItemHeaderDialog from "@/view/components/LineItemHeaderDialog.vue";
import {
  SET_LINE_ITEM,
  SET_CALCULATION,
} from "@/core/services/store/line.item.module";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { toBtxNumber, toBtxFixed } from "@/core/services/common.service";
import CommonMixin from "@/core/plugins/common-mixin.js";
import UpdateDoorType from "@/view/pages/partials/UpdateDoorType.vue";
import UpdateJobDoorType from "@/view/pages/partials/UpdateJobDoorType.vue";
import { v4 as uuidv4 } from "uuid";
import ObjectPath from "object-path";
import { filter, cloneDeep, clone } from "lodash";
import { toSafeNumber, safeMultiply, safeAdd } from "@/core/lib/math.lib";

export default {
  name: "line-item-v2",
  mixins: [CommonMixin],
  data() {
    return {
      drag: false,
      is_custom_door_type: false,
      additional_rows: [{ label: null, value: null }],
      dialog_key: Number(new Date()),
      vWidth: {
        sno: 30,
        unit: 100,
        quantity: 100,
        discount: 100,
        rate: 100,
        total: 100,
        action: 80,
      },
      product_type: null,
      showBreakdown: true,
      hideValue: false,
      door_dialog_key: Number(new Date()),
      header_dialog_key: Number(new Date()),
      header_dialog: false,
      header_location: null,
      header_index: null,
      product_location: null,
      product_dialog: false,
      child_index: -1,
      append_child: false,
      tax_active: true,
      show_price: true,
      show_image: false,
      is_second_child: false,
      discount_level: "transaction",
      selected_line_items: [],
      subtotal: 0,
      discount: 0,
      gst: 0,
      gst_amount: 0,
      adjustment: 0,
      total: 0,
      ctx_discount_label: null,
      ctx_discount_value: null,
      ctx_discount_type: 1,
      ctx_discount: null,
      discount_value: null,
      discount_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      def_object: {
        serial_no: null,
        size: null,
        location: null,
        qty: 0,
        price: null,
        total: null,
      },
      qty_object: {},
      qty_array: [],
      qty_index: -1,
      qty_dialog: false,
      qty_job_dialog: false,
      force_qt: false,
      force_quote_type: false,
      enquiryId: 0,
    };
  },
  props: {
    isJob: {
      type: Boolean,
      default: false,
    },
    quoteType: {
      type: Number,
      default: 1,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onPaste(event) {
      event.preventDefault();
    },
    push_add_rows() {
      this.additional_rows.push({
        label: null,
        value: null,
      });
      this.emit_values();
    },
    remove_add_rows(index) {
      this.additional_rows.splice(index, 1);
      if (!this.additional_rows.length) {
        this.push_add_rows();
      } else {
        this.emit_values();
      }
    },
    save_qty(param) {
      const qty_array = cloneDeep(param);

      const filtered_array = qty_array.filter((item) =>
        Object.values(item).some((value) => value !== null)
      );

      if (this.quoteType == 2) {
        if (!this.selected_line_items[this.qty_index]?.is_custom_door_type) {
          ObjectPath.set(
            this.selected_line_items,
            `${this.qty_index}.quantity`,
            filtered_array.length
          );
        }
      } else if (this.quoteType == 1) {
        ObjectPath.set(
          this.selected_line_items,
          `${this.qty_index}.total`,
          filtered_array.reduce((acc, item) => acc + item.price, 0)
        );
      }

      ObjectPath.set(
        this.selected_line_items,
        `${this.qty_index}.qty_array`,
        filtered_array
      );

      this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);

      this.close_qty_dialog();

      this.calculate_total();
    },
    update_breakdown(status) {
      this.showBreakdown = status;
      if (!status) {
        this.update_hide_door_values(status);
      }
      ObjectPath.set(
        this.selected_line_items,
        `${this.qty_index}.hide_breakdown`,
        !status
      );
    },
    update_hide_door_values(status) {
      this.hideValue = status;
      ObjectPath.set(
        this.selected_line_items,
        `${this.qty_index}.hide_door_values`,
        status
      );
    },
    close_qty_dialog() {
      this.qty_object = {};
      this.qty_array = [];
      this.qty_index = -1;
      this.qty_dialog = false;
      this.qty_job_dialog = false;
      this.force_qt = false;
      this.$nextTick(() => {
        ClearEventBus.$emit("clear:error");
      });
    },
    update_qty_dialog(row, index, default_val) {
      let def_object = cloneDeep(this.def_object);

      if (row.is_custom_door_type && !def_object.serial_no) {
        def_object.serial_no = "NA";
      }

      let default_qty = [{ ...def_object }];

      if (typeof default_val == "object" && default_val.length > 0) {
        default_qty = default_val;
      }

      this.door_dialog_key = Number(new Date());
      this.qty_object = row;
      this.qty_index = index;
      this.qty_array = [];
      if (this.isJob) {
        const qty_array = cloneDeep(default_qty);

        for (let i = 0; i < qty_array.length; i++) {
          for (let z = 0; z < qty_array[i].qty; z++) {
            this.qty_array.push({
              qty: 1,
              size: qty_array[i].size,
              price: qty_array[i].price,
              total: qty_array[i].total,
              width: qty_array[i].width,
              height: qty_array[i].height,
              location: qty_array[i].location,
              serial_no: qty_array[i].serial_no,
            });
          }
        }

        this.qty_job_dialog = true;
      } else {
        this.qty_array = cloneDeep(default_qty);
        this.showBreakdown = !row.hide_breakdown;
        this.hideValue = row.hide_door_values;
        this.qty_dialog = true;
      }

      this.force_qt = row.force_quote_type;
    },
    change_drag() {
      this.update_child_attribute();
    },
    update_child_attribute() {
      let round = 0;
      this.calculate_total();
      this.$nextTick(() => {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          let p_index = i - 1;
          this.selected_line_items[i].has_child = false;
          let is_second_child = ObjectPath.has(
            this.selected_line_items[p_index],
            "is_second_child"
          );
          let has_second_child = ObjectPath.has(
            this.selected_line_items[p_index],
            "has_second_child"
          );
          if (!is_second_child && !has_second_child) {
            if (!this.selected_line_items[i]?.is_child) {
              round = 0;
            }
          }
          if (round == 1) {
            if (!is_second_child && !has_second_child) {
              this.selected_line_items[p_index].has_child = true;
              this.update_total_child_sum(
                this.selected_line_items[p_index].sum_rows,
                p_index
              );
            }
          }
          round++;
        }

        this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);
      });
    },
    update_total_child_sum(status, index) {
      if (status) {
        let total_amount = 0;
        let status = true;
        let t_index = index;
        while (status) {
          t_index++;
          let row = this.selected_line_items[t_index];
          if (!row) {
            status = false;
            break;
          }
          if (!row.is_child) {
            status = false;
            break;
          }
          total_amount += row.total;
        }
        this.selected_line_items[index].total = total_amount;
      }
    },
    auto_adjust() {
      let total = toBtxNumber(this.total);
      if (total > 0) {
        let adjustment = toBtxFixed(total / 10) * 10 - total;
        this.adjustment = toBtxFixed(adjustment);
        this.$nextTick(() => {
          this.calculate_total();
        });
      }
    },
    get_col_style(param, child, second_child) {
      let width = 50;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 40;
          if (this.discount_level == "transaction") {
            width = 50;
          }
        }
      }
      if (second_child) {
        return { width: `${width - 10}%` };
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    calculate_total() {
      let d_type = this.discount_level;

      this.subtotal = 0;
      this.discount = 0;
      this.gst = 0;
      this.gst_amount = 0;

      if (this.quoteType == 2) {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          let total = 0;

          let type = this.selected_line_items[i].type;

          if (type == "door_type") {
            total = safeMultiply(
              this.selected_line_items[i].quantity,
              this.selected_line_items[i].rate,
              this.contractScheduleLength
            );
          } else {
            total = safeMultiply(
              this.selected_line_items[i].rate,
              this.selected_line_items[i].quantity
            );
          }

          if (total <= 0) {
            this.selected_line_items[i].total = 0;
            continue;
          }

          this.selected_line_items[i].total = total;
          this.subtotal = safeAdd(this.subtotal, total);
        }
      } else if (this.quoteType == 1) {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          let type = this.selected_line_items[i].type;

          let total = 0;

          if (type == "door_type") {
            let qty_array = this.selected_line_items[i].qty_array;

            if (Array.isArray(qty_array)) {
              for (let i = 0; i < qty_array.length; i++) {
                const sub_total = safeMultiply(
                  qty_array[i].qty,
                  qty_array[i].price
                );
                total = safeAdd(total, sub_total);
              }
            }
          } else {
            total = safeMultiply(
              this.selected_line_items[i].rate,
              this.selected_line_items[i].quantity
            );
          }

          if (total <= 0) {
            this.selected_line_items[i].total = 0;
            continue;
          }

          this.selected_line_items[i].total = total;
          this.subtotal = safeAdd(this.subtotal, total);
        }
      } else {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          if (this.selected_line_items[i].sum_rows) {
            continue;
          }
          let total = this.accountingToFixed(
            toBtxNumber(this.selected_line_items[i].quantity) *
              toBtxNumber(this.selected_line_items[i].rate)
          );
          if (total <= 0) {
            this.selected_line_items[i].total = 0;
            continue;
          }
          let discount = 0;
          if (d_type == "line_item") {
            if (this.selected_line_items[i].discount_type == 1) {
              discount = toBtxNumber(
                this.selected_line_items[i].discount_value
              );
            }
            if (this.selected_line_items[i].discount_type == 2) {
              discount = this.accountingToFixed(
                (toBtxNumber(total) *
                  toBtxNumber(this.selected_line_items[i].discount_value)) /
                  100
              );
            }
          }
          this.selected_line_items[i].total =
            isNaN(total) || !total
              ? 0
              : toBtxNumber(total) - toBtxNumber(discount);
          this.subtotal = this.accountingToFixed(
            toBtxNumber(this.subtotal) +
              toBtxNumber(this.selected_line_items[i].total)
          );
        }
      }

      let subtotal = this.subtotal;

      if (d_type == "transaction") {
        this.discount = 0;
        if (this.discount_type == 1) {
          this.discount = toBtxNumber(this.discount_value);

          if (this.discount > this.subtotal) {
            this.discount_value = toBtxNumber(this.subtotal);
          }
        }
        if (this.discount_type == 2) {
          if (this.discount_value > 100) {
            this.discount_value = 100;
          }
          this.discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.discount);

      // custom discount

      if (d_type == "transaction") {
        this.ctx_discount = 0;
        if (this.ctx_discount_type == 1) {
          this.ctx_discount = toBtxNumber(this.ctx_discount_value);
          if (this.ctx_discount > this.subtotal) {
            this.ctx_discount_value = toBtxNumber(this.subtotal);
          }
        }
        if (this.ctx_discount_type == 2) {
          if (this.ctx_discount_value > 100) {
            this.ctx_discount_value = 100;
          }
          this.ctx_discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.ctx_discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.ctx_discount);

      if (this.tax_active) {
        this.gst_amount = subtotal;
        this.gst = this.accountingToFixed(
          (toBtxNumber(subtotal) * toBtxNumber(this.lGST)) / 100
        );
      }

      subtotal = toBtxNumber(subtotal) + toBtxNumber(this.gst);

      this.total = this.accountingToFixed(
        toBtxNumber(subtotal) + toBtxNumber(this.adjustment)
      );
      this.emit_values();
    },
    update_line_item(param) {
      if (this.append_child) {
        let child_index = toBtxNumber(this.child_index);
        for (let item of param) {
          item.is_child = !this.is_second_child;
          item.is_second_child = this.is_second_child;
          child_index = child_index + 1;
          this.selected_line_items = this.arrayInsert(
            this.selected_line_items,
            child_index,
            item
          );
        }
        if (this.is_second_child) {
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_child = false;
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_second_child = true;
        } else {
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_child = true;
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_second_child = false;
        }
      } else {
        if (this.quoteType == 2) {
          for (let item of param) {
            const existIndex = this.selected_line_items.findIndex(
              (obj) =>
                obj.product_id === item.product_id &&
                obj.product_type === item.product_type &&
                obj.request_type === item.request_type
            );
            if (existIndex > -1) {
              let item_qty_array = cloneDeep(item.qty_array);
              let pre_qty_array = cloneDeep(
                this.selected_line_items[existIndex].qty_array
              );
              if (!Array.isArray(item_qty_array)) {
                item_qty_array = [];
              }
              if (!Array.isArray(pre_qty_array)) {
                pre_qty_array = [];
              }
              for (let i = 0; i < item_qty_array.length; i++) {
                const preIndex = pre_qty_array.findIndex(
                  (obj) => obj.id === item.id
                );
                if (preIndex > -1) {
                  pre_qty_array[preIndex] = item_qty_array[i];
                } else {
                  pre_qty_array.push(item_qty_array[i]);
                }
              }

              this.selected_line_items[existIndex].qty_array =
                cloneDeep(pre_qty_array);
              this.selected_line_items[existIndex].quantity =
                pre_qty_array.length;
              this.selected_line_items[existIndex].total = pre_qty_array.reduce(
                (acc, item) => acc + item.price,
                0
              );
            } else {
              if (this.product_location == "top") {
                this.selected_line_items.unshift(item);
              } else {
                this.selected_line_items.push(item);
              }
            }
          }
        } else {
          for (let item of param) {
            if (this.product_location == "top") {
              this.selected_line_items.unshift(item);
            } else {
              this.selected_line_items.push(item);
            }
          }
        }
      }

      this.calculate_total();

      this.$nextTick(() => {
        this.emit_values();
        this.is_custom_door_type = false;
        this.product_dialog = false;
        this.append_child = false;
        this.is_second_child = false;
        this.product_type = null;
        this.product_location = null;
        this.child_index = null;
      });
    },
    add_child(index, type, is_child, forceUDT = false) {
      if (type == "door") {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Select Customer");
          return false;
        }
      }

      this.force_quote_type = false;

      if (forceUDT) {
        this.force_quote_type = true;
      }

      if (type == "header") {
        this.add_header("bottom", index);
      } else if (type == "other") {
        this.is_second_child = is_child;
        this.add_other("bottom", index);
      } else {
        this.append_child = true;
        this.dialog_key = Number(new Date());
        this.is_second_child = is_child;
        this.is_custom_door_type = false;
        this.child_index = index;

        const validTypes = ["door", "part", "service", "door_type", "tool"];

        if (validTypes.includes(type)) {
          this.product_type = type;
          this.product_dialog = true;
        }
      }
    },
    emit_values() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_CALCULATION, {
          additional_rows: this.additional_rows?.filter(
            (item) => item.label !== null && item.value !== null
          ),
          ctx_discount_label: this.ctx_discount_label,
          ctx_discount_value: this.ctx_discount_value,
          ctx_discount_type: this.ctx_discount_type,
          ctx_discount: this.ctx_discount,
          discount_type: this.discount_type,
          discount_value: this.discount_value,
          tax_active: this.tax_active,
          adjustment: this.adjustment,
          show_price: this.show_price,
          discount_level: this.discount_level,
          tax_value: this.lGST,
        });
        this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);
      });
      this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);
    },
    open_dialog(location, param, custom) {
      if (param == "door") {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Select Customer");
          return false;
        }
      }

      this.append_child = false;
      this.is_custom_door_type = Boolean(custom);
      this.is_second_child = false;
      this.child_index = -1;

      this.dialog_key = Number(new Date());
      this.product_type = param;
      this.product_location = location;
      this.product_dialog = true;
    },
    add_header(location, index) {
      this.header_location = location;
      this.header_index = index;

      this.header_dialog_key = Number(new Date());
      this.header_dialog = true;
    },
    update_header(params) {
      let headers = cloneDeep(params);

      if (this.header_location == "top") {
        headers = [...headers].reverse();
      }

      for (let index = 0; index < headers.length; index++) {
        const element = headers[index];

        let len = this.selected_line_items.length + 1;
        if (this.header_location == "top") {
          this.selected_line_items.unshift({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: 0,
            order: len,
            type: "header",
            product_type: "header",
            is_child: false,
            has_child: false,
            has_property: false,
            product: element.text,
          });
        } else {
          if (typeof this.index == "number" && this.index > -1) {
            this.selected_line_items.splice(
              this.index,
              0,
              ...[
                {
                  status: true,
                  index: uuidv4(),
                  uuid: uuidv4(),
                  id: 0,
                  order: len,
                  type: "header",
                  product_type: "header",
                  is_child: false,
                  has_child: false,
                  has_property: false,
                  product: element.text,
                },
              ]
            );
          } else {
            this.selected_line_items.push({
              status: true,
              index: uuidv4(),
              uuid: uuidv4(),
              id: 0,
              order: len,
              type: "header",
              product_type: "header",
              is_child: false,
              has_child: false,
              has_property: false,
              product: element.text,
            });
          }
        }
      }

      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    add_other(location, index) {
      let len = this.selected_line_items.length + 1;
      if (location == "top") {
        this.selected_line_items.unshift({
          status: true,
          index: uuidv4(),
          uuid: uuidv4(),
          id: null,
          image: {},
          order: len,
          serial_no: null,
          discount: 0,
          type: "other",
          is_child: false,
          is_second_child: false,
          parent_uuid: null,
          parent_second_uuid: null,
          has_child: false,
          has_second_child: false,
          sum_rows: false,
          show_price: false,
          discount_value: 0,
          custom_val: null,
          discount_type: 1,
          product: null,
          product_id: 0,
          product_type: "other",
          description: null,
          warranty: {},
          rate: 0,
          selling_cost: 0,
          quantity: 1,
          uom: null,
          total: 0,
        });
      } else {
        if (typeof index == "number" && index > -1) {
          this.selected_line_items.splice(index + 1, 0, {
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: null,
            image: {},
            order: len,
            serial_no: null,
            discount: 0,
            type: "other",
            is_child: !this.is_second_child,
            is_second_child: this.is_second_child,
            parent_uuid: null,
            parent_second_uuid: null,
            has_child: false,
            has_second_child: false,
            sum_rows: false,
            show_price: false,
            discount_value: 0,
            custom_val: null,
            discount_type: 1,
            product: null,
            product_id: 0,
            product_type: "other",
            description: null,
            warranty: {},
            rate: 0,
            selling_cost: 0,
            quantity: 1,
            uom: null,
            total: 0,
          });


          // this.selected_line_items.splice(index + 1, 0, ...[{
          /* this.selected_line_items.push({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: null,
            image: {},
            order: len,
            serial_no: null,
            discount: 0,
            type: "other",
            is_child: !this.is_second_child,
            is_second_child: this.is_second_child,
            parent_uuid: null,
            parent_second_uuid: null,
            has_child: false,
            has_second_child: false,
            sum_rows: false,
            show_price: false,
            discount_value: 0,
            custom_val: null,
            discount_type: 1,
            product: null,
            product_id: 0,
            product_type: "other",
            description: null,
            warranty: {},
            rate: 0,
            selling_cost: 0,
            quantity: 1,
            uom: null,
            total: 0,
          }); */
        } else {
          this.selected_line_items.push({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: null,
            image: {},
            order: len,
            serial_no: null,
            discount: 0,
            type: "other",
            is_child: false,
            is_second_child: false,
            parent_uuid: null,
            parent_second_uuid: null,
            has_child: false,
            has_second_child: false,
            sum_rows: false,
            show_price: false,
            discount_value: 0,
            custom_val: null,
            discount_type: 1,
            product: null,
            product_id: 0,
            product_type: "other",
            description: null,
            warranty: {},
            rate: 0,
            selling_cost: 0,
            quantity: 1,
            uom: null,
            total: 0,
          });
        }
      }

      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    remove_row(index) {
      this.selected_line_items.splice(index, 1);
      this.calculate_total();
      this.emit_values();
    },
    resetCustomerDoor() {
      this.selected_line_items = filter(this.selected_line_items, (row) => {
        return row.product_type != "door";
      });
      this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);
      this.calculate_total();
      this.emit_values();
    },
  },
  mounted() {
    this.enquiryId = Number(this.$route?.query?.enquiry ?? 0);
    /* if (this.enquiryId > 0 && this.isJob) {
      this.add_other("top");
    } */
  },
  components: {
    Draggable,
    UpdateDoorType,
    UpdateJobDoorType,
    LineItemDialog,
    LineItemHeaderDialog,
  },
  watch: {
    vCustomerId() {
      this.resetCustomerDoor();
    },
    lEditLineItem() {
      this.selected_line_items = this.lEditLineItem;
      this.calculate_total();
    },
    lEditCalculation() {
      this.additional_rows = this.lEditCalculation?.additional_rows ?? [];
      this.discount_type = this.lEditCalculation?.discount_type;
      this.discount_value = this.lEditCalculation?.discount_value;
      this.tax_active = this.lEditCalculation?.tax_active;
      this.adjustment = this.lEditCalculation?.adjustment;
      this.show_price = this.lEditCalculation?.show_price;
      this.discount_level = this.lEditCalculation?.discount_level;
      this.ctx_discount_label = this.lEditCalculation?.ctx_discount_label;
      this.ctx_discount_value = this.lEditCalculation?.ctx_discount_value;
      this.ctx_discount_type = this.lEditCalculation?.ctx_discount_type;
      this.calculate_total();
      this.push_add_rows();
    },
  },
  computed: {
    dialog_width() {
      return toSafeNumber((document.body.clientWidth / 100) * 60);
    },
    ...mapGetters([
      "lGST",
      "lUOM",
      "contractScheduleLength",
      "vCustomerId",
      "lProcessing",
      "lEditLineItem",
      "lEditCalculation",
    ]),
  },
};
</script>
